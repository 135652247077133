* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;

  ;
}



*::-webkit-scrollbar {
  width: none ;
  display: none ;
}

.scrollbar-track{
  background-color: transparent !important;
}

.scrollbar-track-y{
  width:12px !important;
}


.scrollbar-thumb{
  width:12px !important;
  background-color: transparent !important;
  border-radius: 0 !important;
}
/* 
html {
  scroll-behavior: smooth;
} */

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: rgba(248, 247, 247, 1);
 
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body:focus {
  outline: none !important;
}

ol, ul {
  padding-inline-start: 40px;
}

/* Style the input fields */
input {
  /* Set the desired text color */
  font-size: 30 !important;
  /* Set the desired background color */
  background-color: transparent !important;
  /* Add any other desired styles */
}

/* Handle autofill styles */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  /* Reset text color */
  -webkit-text-fill-color: rgb(8, 2, 2) !important;

  /* Reset background color */
  /*-webkit-box-shadow: 0 0 0 30px #898c90 inset !important;*/
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.back-office-text {
  font-family: "Inter";
  font-size:12px;
  font-weight: 500;
  color: #000000;
}

/* MENU STYLES - BEGIN -  */
.menu-container  {
  padding: 12px;
  height: 100vh;
  background: white;
}

.menu-inner-container {
  border: solid 1px black;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: scroll;
}

.menu-item-container{
  padding: 40px 0px;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 20px;
}

.menu-item-container > .menu-item-text {
  cursor: pointer;
  font-size: 16px;
  text-align: center;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.menu-item-container > div > .menu-item-divider {
  width: 5px;
  height: 5px;
  background: rgba(221, 212, 197, 1);
  border-radius: 50%;
}

.menu-item-container > .menu-item-social-media-links {
  display: flex;
  row-gap: 10px;
  column-gap: 20px;
  margin-top: 10px;
  justify-content: space-between;
}

.menu-item-privacy-container {
  display: flex;
  flex-direction: column;
  row-gap: 15px;
  align-items: center;
}

.menu-item-privacy-container > .menu-item-privacy-inner-container {
  margin: 30px 0px;
  height: 1px;
  background-color: lightgray;
  width: 250px;
}

.menu-item-privacy-container > .menu-item-privacy-text {
  cursor: pointer;
  font-size: 11px;
  font-weight: 400;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.menu-item-splitter {
  margin: 20px 0px;
  height: 1px;
  background-color: lightgray;
  width: 250px;
}

/* MENU STYLES - END */

/* TABLE NAVIGATION - START - */

.table-navigation-container {
  display: flex;
  align-items: center;
  background-color: white;
  justify-content: end;
  padding: 8px 10px 10px 0px;
  border-radius: 0px 0px 6px 6px;
}

.table-navigation-container > nav > ul > li {
  min-width: 36px;
  width: 36px;
  max-width: 36px;
  min-height: 36px;
  height: 36px;
  max-height: 36px;
  border-radius: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.table-navigation-container > nav > ul > li > button {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  font-size: 12px;
}

.table-navigation-container > nav > ul > li > button:hover{
  background-color: transparent;
  color: rgba(3, 7, 18, 1);
}

.table-navigation-container > nav > ul > li:last-child > button:hover, 
.table-navigation-container > nav > ul > li:first-child > button:hover{
  background-color: transparent;
  color: rgba(3, 7, 18, 1);
}

.table-navigation-container > nav > ul > li > button.Mui-selected{
  background-color: rgba(3, 7, 18, 1);
  color: white;
}
.table-navigation-container > nav > ul > li > button.Mui-selected:hover {
  background-color: rgba(3, 7, 18, 1);
  color: white;
}


/* TABLE NAVIGATION - END - */

/* BLOG MANAGEMENT - LIST - START */

.input-blog-search {
  width: 100%;
  padding: 20px 3px;
  border-radius: 15px;
  font-family: "Inter";
}

.blog-admin-container {
  width: 100%;
  display: flex;
  gap: 20px;
  margin-top: 30px;
}

.blog-admin-containers-right {
  width: 20%;
  max-width: 210px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.data-blog-count-container {
  display: flex;
  align-items: start;
  gap: 20px;
  margin-top: 50px;
  margin-left: 30px;
}

.data-blog-count-text {
  font-size: 50px;
  font-weight: 400;
  line-height: 50px;
}

.data-blog-inner-container {
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.data-category-title {
  font-size: 13px;
  font-weight: 400;
  text-transform: uppercase;
}

.data-category-container {
  display: "flex";
  flex-direction: column;
  justify-content: center;
  gap: 0px;
}

.data-category-inner-container {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 20px;
}

.data-category-color {
  min-width:12px;
  width: 12px;
  max-width: 12px;
  min-height: 12px;
  height: 12px;
  max-height: 12px;
  border-radius: 50%;
}

.data-category-text {
  text-transform: uppercase;
  font-size: 10px;
  font-family: "Inter";
  font-weight: 400;
}
ul.MuiList-root > li {
  padding-top: 0px;
  padding-bottom: 0px;
  border: none;
}

ul.MuiList-root > li > div {
  margin-left: 18px;
  margin-right: 19px;
  padding-top: 6px;
  padding-bottom: 6px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  width: 100%;
}

ul.MuiList-root > li:last-child > div {
  border:none;
}

ul.MuiList-root > li:last-child {
  border-bottom:none;
}
h1.blog-content-title-heading-h1 {
  color: #000;
  text-align: center;
  font-family: "Orpheus Pro";
  font-size: 40px;
  font-weight: 400;
  line-height: 45px; /* 112.5% */
  letter-spacing: 0.8px;
}

h1.blog-content-title-heading-h1-mobile {
  color: #000;
  text-align: center;
  font-family: "Orpheus Pro";
  font-size: 18px;
  font-weight: 400;
  line-height: 38px; /* 112.5% */
  letter-spacing: 0.8px;
}

h2.blog-content-subtitle-heading-h2 {
  color: rgba(0, 0, 0, 0.70);
  text-align: center;
  font-family: "Orpheus Pro";
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 117.647% */
}

h3.blog-content-h3-heading {
  color: rgba(0, 0, 0, 0.50);
  font-family: "Orpheus Pro";
  font-size: 30px;
  font-style: italic;
  font-weight: 400;
  line-height: 20px; /* 66.667% */
  margin-top: 50px;
  margin-bottom:29px;
}

legend.blog-content-image-legend > * {
  color: rgba(0, 0, 0, 0.50);
  font-family: "Orpheus Pro";
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 166.667% */
  padding-top: 10.9px;
}

/* BLOG MANAGEMENT - LIST - END */

/* PIT CREATE OR EDIT - START - */

.pit-faq-new .single-line > .ProseMirror {
  padding: 15px 20px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

.pit-faq-new .question_pt-inputbox .tiptap.ProseMirror,
.pit-faq-new .question_en-inputbox .tiptap.ProseMirror,
.pit-faq-new .question_es-inputbox .tiptap.ProseMirror {
  color: #000000;
  font-family: "Inter";
  font-size: 17px;
  font-style:normal;
  line-height: 20px;
  font-weight: 600;
  min-height: 27px;
}

.pit-faq-list,
.pit-faq-new {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  border-radius: 20px;
}

.pit-faq-list > div,
.pit-faq-new > div {
  display: flex;
  width: 100%;
  overflow: hidden;
}

.pit-faq-list > div:first-child > div > div, 
.pit-faq-new > div:first-child > div > div {
  border-radius: 20px 20px 0px 0px;
}

.pit-faq-list > div:last-child,
.pit-faq-new > div:last-child {
  border-radius: 0px 0px 20px 20px;
}

.pit-faq-list > div > div > div,
.pit-faq-new > div > div > div {
  background-color: white;
}

.opacity-heading > div {
  opacity: 50%;
}

.pit-faq-list .opacity-heading > div:first-child > div > div {
  /*opacity: 50%;*/
}

.pit-faq-list .opacity-heading .opacity .ProseMirror {
  opacity: 100%;
}

.pit-faq-list .tiptap.ProseMirror {
  border-radius: 0px !important;
}

.pit-faq-list .faq-question .tiptap.ProseMirror {
  padding: 0px;
  color: #000000;
  font-family: "Inter";
  font-size: 17px;
  font-style:normal;
  line-height: 20px;
  font-weight: 600;
  min-height: 27px;
}

/* PIT CREATE OR EDIT - END - */

/* SCROLLBAR */
.custom-scrollbar::-webkit-scrollbar {
	width: 4px!important;
}