.border {
    position: relative; /* Ensure the parent element is relatively positioned */
  }
  
  .border::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 11px; /* Width of the border */
    background: linear-gradient(180deg, #AEB4B7 39.5%, #F9F9F9 100%);
  }
  