.box {
  border: solid 2px black;
  width: 35px;
  height: 35px;

  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
}
